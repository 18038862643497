<template>
    <div>  
        View All Pages
       <router-view :key="$route.path"></router-view>
    </div>
</template>

<script>


export default {
    name: "ViewAll",
    
    data() {
       // console.log('hi')
        return {
            
            id: this.$route.params.id,
        };
    },
    methods: {
        loadProduct() {
            let url = `top/item/products`;
            this.$store.dispatch(GET_PRODUCT_LISTS,url);
        },
        
    },
    created() {
        console.log('type',this.$route.params.type)
    }
   
}
</script>

<style>

</style>
